import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { IResponse } from '@shared/interfaces/iresponse';
import { MainSettingsModel } from 'app/website-settings/models/main-settings-model';
import { environment } from 'environments/environment';
import { Observable, map, catchError, of, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BootstrapService {

  MainSetting: BehaviorSubject<MainSettingsModel> = new BehaviorSubject(null);
  SiteName: string ="Marketplace";
  constructor(private httpClient: HttpClient) { }

  loadSetting(): Observable<boolean> {
    return this.httpClient.get<any>(`${environment.ctUrl}Settings/FetchMainSettings`).pipe(
      map((response: IResponse) => {
        this.SiteName = response.Data?.SiteName || "Marketplace";
        this.MainSetting.next(response.Data);
        localStorage.setItem("CommentsEnabled",`${response.Data?.Comments ?? false}`);
        return true;
      }),
      catchError((error) => {
        return of(false);
      })
    );
  }

  get WebsiteSetting() {
    return this.MainSetting.value;
  }

}
